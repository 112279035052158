import React from 'react'
import css from '../Hero/Hero.module.css'
import HeroImg from "../../assets/Heroo.png"
import {RiArrowRightCircleFill, RiShoppingBagFill} from 'react-icons/ri'
import {motion} from 'framer-motion'

export const Hero = () => {

  const transition = {duration: 3, type: "spring"}

  return (
    <div className={css.container}>

        {/* Left Side */}
        <div className={css.h_sides}>
          <span className={css.text1}>Welcome To Our Store</span>

          <div className={css.text2}>
            <span>Trendy Collection</span>
            <span>
              {" "}
              Lorem ipsum dolor sit amet consectetur adipisicing elit. dsadasda.
              </span>
          </div>
        </div>

        {/* Middle Side Hero Image */}
        <div className={css.wrapper}>

          {/* Pink Circle */}
          <motion.div
          initial={{bottom: "-2rem"}}
          whileInView={{bottom: "0rem"}}
          className={css.pinkCircle}
          transition={transition}
          ></motion.div>

          {/* Hero Img */}
            <motion.img
            transition={transition}
            initial={{bottom: "2rem"}}
            whileInView={{bottom: "0rem"}}
            src={HeroImg} alt="" width={600}/>

            {/* Cart div animating */}
            <motion.div
            transition={transition}
            initial={{right: "4%"}}
            whileInView={{right : "2%"}}
            className={css.cart2}>
              <RiShoppingBagFill/>

              <div className={css.signup}>
                <span>Best Signup Offers</span>

                <div>
                  <RiArrowRightCircleFill/>
                </div>
              </div>
            </motion.div>
        </div>

        {/* Right Side */}
        <div className={css.h_sides}>
          <div className={css.traffic}>
            <span>1.5m</span>
            <span>Monthly Traffic</span>
          </div>

          <div className={css.customers}>
            <span>100k</span>
            <span>Happy Customer</span>
          </div>
        </div>
      </div>
  )
}

export default Hero;